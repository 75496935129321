import React from 'react'

import { ReactComponent as CheckIconActive } from '../shared/icons/CheckIconActive.svg'
import { ReactComponent as CheckIconInactive } from '../shared/icons/CheckIconInactive.svg'

/**
 * @TODO: move these colors to the theme palette
 */
const grey = {
  main: '#EFEFF3'
}

const NanolikeTheme = {
  palette: {
    type: 'light',
    // Mui colors
    primary: {
      main: '#0F0B37',
      dark: '#0F0B37'
    },
    secondary: {
      main: '#42A37F',
      contrastText: '#ffffff'
    },
    error: {
      main: '#E83E6D'
    },
    warning: {
      main: '#FFB74D',
      light: '#fff4e4'
    },
    success: {
      main: '#81C784'
    },
    // Custom colors
    calibration: {
      main: '#4B67CD',
      light: '#DFE4F6',
      dark: '#2E48A7',
      contrastText: '#ffffff'
    },
    avatarBlue: {
      main: '#ADEBFF',
      contrastText: '#1BAEDE'
    },
    nanoBlueHighlight: {
      main: '#383271'
    },
    pending: {
      light: '#F3F5FC',
      contrastText: '#ffffff',
      main: '#2E48A7'
    },
    calibrating: {
      main: '#5D9E9D'
    },
    ok: {
      main: '#D3F0D3',
      contrastText: '#18B915'
    },
    decorative: {
      contrastText: '#B39119',
      main: '#F6EABF'
    },
    priorityMedium: {
      contrastText: '#F49855',
      main: '#FCE2CF',
      dark: '#D35900'
    },
    problem: {
      light: '#FCDCE5',
      // contrastText: '#E83E6D',
      contrastText: '#ffffff',
      main: '#E83E6D'
    },
    priorityHigh: {
      contrastText: '#E83E6D',
      main: '#FCDCE5',
      dark: '#CD010D'
    },
    priorityLow: {
      contrastText: '#6ED56C',
      main: '#E7E6ED',
      dark: '#007C4D'
    },
    priorityHighRevert: {
      contrastText: '#FCDCE5',
      main: '#E83E6D'
    },
    grey: {
      main: '#EFEFF3'
    },
    greyBackground: {
      main: '#FAFAFB'
    },
    black: {
      main: '#000000'
    },
    text: {
      primary: '#000000',
      secondary: '#6B6B86'
    },
    action: {
      disabledBackground: '#BDBAD5',
      disabled: 'white'
    },
    graph: {
      main: '#FFAB40',
      text: '#a04d00'
    }
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 850,
      md: 1000,
      lg: 1230,
      xl: 1536
    }
  },

  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontWeight: 700,
      fontSize: 24
    },
    h2: {
      fontWeight: 700,
      fontSize: 20
    },
    h4: {
      fontWeight: 600,
      fontSize: 16
    },
    h5: {
      fontWeight: 600,
      fontSize: 14
    },
    h6: {
      fontWeight: 600,
      textTransform: 'none',
      fontSize: 12
    },
    button: {
      fontWeight: 600,
      textTransform: 'none',
      fontSize: 14
    },
    body1: {
      fontWeight: 500,
      textTransform: 'none',
      fontSize: 14
    },
    body2: {
      fontWeight: 500,
      textTransform: 'none',
      fontSize: 12
    },
    caption: {
      color: 'white'
    }
  },
  shape: {
    borderRadius: 10
  },
  components: {

    MuiSkeleton: {
      styleOverrides: {
        rounded: {
          borderRadius: 10
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckIconInactive />,
        checkedIcon: <CheckIconActive />
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          ':last-of-type': { borderBottom: 'none' },
          paddingTop: 15,
          paddingBottom: 15,
          paddingLeft: 0,
          paddingRight: 0,
          ':focus': {
            backgroundColor: 'transparent'
          },
          ':hover': {
            backgroundImage: 'linear-gradient(272deg, #fff, #FAFAFB, #fff)'
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent'
          }
        }
      }
    },

    MuiTableCell: {
      defaultProps: {
        component: 'div'
      },
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          width: '100%',
          padding: 0,
          paddingRight: 0,
          borderBottom: 0,
          fontWeight: 500,
          fontSize: 15
        },
        head: {
          fontWeight: 600,
          fontSize: 12,
          textTransform: 'uppercase'
        }
      }
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.grey.main
        })
      }
    },
    MuiChip: {
      styleOverrides: {

        label: {
          fontWeight: 500,
          fontSize: 15
        },
        root: {
          borderRadius: 10
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          // @FIXME: flex-start for alert modal, customview modal...  but center for others dialog
          alignItems: 'center'
        },
        // There is an issue with the scroll when dialog contains form
        // https://github.com/mui/material-ui/issues/13253
        // Elegant fix :
        paper: {
          '& > form': {
            display: 'contents'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: 'black',
          fontWeight: 700,
          fontSize: 24,
          textAlign: 'center'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: 'black'
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: 15,
          textAlign: 'center',
          paddingBottom: 24
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.08) !important',
          top: 16
        },
        list: {
          paddingTop: 16,
          paddingLeft: 16,
          paddingRight: '16px !important',
          paddingBottom: 16
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: '1.0rem'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupUl: {
          paddingLeft: 24,
          paddingRight: 24,
          '& .MuiAutocomplete-option': {
            paddingLeft: 0,
            paddingRight: 0
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        p: 0,
        variant: 'scrollable',
        indicator: {
          display: 'flex',
          backgroundColor: '#42A37F'
        }
      }
    },

    MuiSlider: {

      styleOverrides: {
        root: {
          color: '#C5C5D3',
          '& .MuiSlider-mark': {
            height: 8,
            width: 8,
            borderRadius: 8,
            backgroundColor: grey.main,
            '&.MuiSlider-markActive': {
              opacity: 1,
              backgroundColor: 'currentColor'
            }
          },
          '& .MuiSlider-thumb': {
            height: 28,
            width: 28,
            borderRadius: 28,
            color: '#42A37E',
            boxShadow: 'none',
            '& .bar': {
              height: 10,
              width: 1,
              backgroundColor: '#FFFFFF',
              marginLeft: 1,
              borderRadius: '99px',
              marginRight: 1
            }
          },
          '& .MuiSlider-track': {
            height: '1px',
            border: '1px',
            color: '#C5C5D3'
          },
          '& .MuiSlider-rail': {
            height: '1px',
            color: grey.main,
            border: '1px'
          }
        }

      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: 12
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiTab-root': {
            marginRight: '16px'
          }
        }
      },
      defaultProps: {
        disableRipple: true // deactivate shadow background when clicking or hovering
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true // deactivate shadow background when clicking or hovering
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'secondary',
        size: 'large'
      },
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          fontSize: 14,
          fontWeight: '600',
          height: 40
        }
      }
    },

    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
        color: 'secondary',
        size: 'large'
      },
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          padding: '16px 24px',
          height: 50
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: { color: 'black !important' },
        outlined: {
          transform: 'none !important'
        }

      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          fontSize: '1.75em'
        },
        select: {
          '&:focus': {
            background: 'transparent '
          },
          '&.MuiSelect-select': {
            '&.MuiInputBase-input': {
              '&.MuiOutlinedInput-input': {
                paddingLeft: '8px',
                paddingRight: '32px'
              }
            }
          }
        }
      }

    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          'label + &': {
            marginTop: '27px'
          },
          backgroundColor: grey.main,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: grey.main
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: grey.main
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: grey.main
          }
        }
      }
    }
  }
}

export default NanolikeTheme
