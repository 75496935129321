import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Typography, Popover, useTheme, useMediaQuery, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { WARNING_CAUSE } from '../models/operationSuggested'
import { IceTrappedIcon, InfoIcon } from '../icons/index'

const WARNING_CAUSE_ICON = Object.freeze({
  [WARNING_CAUSE.ICE]: IceTrappedIcon
})

const WARNING_CAUSE_SHORT = Object.freeze({
  // [WARNING_CAUSE.ICE]: 'device_warning_cause_ice_short'
})

const WARNING_CAUSE_TITLE = Object.freeze({
  [WARNING_CAUSE.ICE]: 'device_warning_cause_ice_title'
})

const WARNING_CAUSE_EXPLANATION = Object.freeze({
  [WARNING_CAUSE.ICE]: 'device_warning_cause_ice_explanation'
})

const getWarningCauseTitle = (t, warningCause) => {
  // The day we add links to docs, we will use something like this
  // return (
  //   <Trans
  //     i18nKey='test_tags'
  //     values={{ href: 'value' }}
  //     components={{ link: <a href='https://doclink...' rel='noreferrer' /> }}
  //   />
  // )
  return t(WARNING_CAUSE_TITLE[warningCause] ?? 'device_status_problem_unknown_title')
}

const getWarningCauseExplanation = (t, warningCause) => {
  return t(WARNING_CAUSE_EXPLANATION[warningCause] ?? 'device_status_problem_unknown_explanation', { product: 'BinConnect' })
}

const getWarningCauseIcon = (warningCause) => {
  return WARNING_CAUSE_ICON[warningCause] ?? InfoIcon
}

const propTypes = {
  warningCause: PropTypes.string
}

function WarningCauseInfo ({ warningCause }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  if (!warningCause) return null

  const iconStyles = {
    color: theme.palette.warning.main, height: 24, width: 24
  }
  const Icon = getWarningCauseIcon(warningCause)
  const info = WARNING_CAUSE_SHORT[warningCause]
  const explanation = getWarningCauseExplanation(t, warningCause)
  const title = getWarningCauseTitle(t, warningCause)

  // The case may not be handled yet, like NEW_TAPPING
  if (!Icon) return null

  return (
    <Stack
      spacing={0.5} direction='row' justifyContent='flex-end' alignItems='center'
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup='true'
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Icon {...iconStyles} />
      {!mobileFormat && (
        <Typography variant='h5' color='warning.main' style={{ wordBreak: 'break-word', marginLeft: 8 }}>{t(info)}</Typography>
      )}
      {!mobileFormat && (
        <Popover
          id='mouse-over-popover'
          sx={{
            pointerEvents: 'none'
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box p={3} maxWidth={400}>
            <Typography variant='h4' color='text.primary' pb={2}>
              {title}
            </Typography>
            <Typography variant='body1' color='text.secondary'>
              {explanation}
            </Typography>
          </Box>
        </Popover>
      )}
    </Stack>
  )
}

WarningCauseInfo.propTypes = propTypes

export { getWarningCauseExplanation, getWarningCauseTitle, getWarningCauseIcon }
export default WarningCauseInfo
